#mc_embed_signup {
  @color-red: #eb4747;

  .indicates-required {
    text-align: right;
    font-size: 0.85rem;
    margin-right: .5em;
  }
  .asterisk {
    color: @color-red;
    font-size: 1.25rem;
    font-weight: bold;
    position: relative;
    top: .25rem;
  }
  .fields {
    margin: 0 0.5em 1em;

    display: grid;
    grid-gap: 0.5em;
    grid-template-columns: repeat(2, 1fr);

    .size1of2 {
      grid-column: span 1;
    }
    .size2of2 {
      grid-column: span 2;
    }
    p.size1of2 {
      justify-self: right;
      align-self: center;
    }

    .mc-field-group {
      width: 100%;
      clear: left;

      label {
        display: block;
        margin-bottom: 0.2em;
      }

      input {
        display: block;
        box-sizing: border-box;
        width: 100%;
        margin-bottom: 1em;
        padding: 0.5em;
        border-style: solid;
        border-width: .2em;
        border-radius: .2em;
        border-color: var(--color-button-border);

        &:invalid {
          border-color: @color-red;
        }

        &.birthday {
          display: inline;
          width: 5em;
          text-align: center;
          text-indent: unset;
        }
      }
    }
  }

  input:focus {
    border-color: var(--color-accent);
  }

  .buttons {
    padding: 0;
  }
}
